<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-toggle collapsed" (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand">
        <img [src]="appInfo.logo.horizontal" class="logo-header" alt="" />
        <!-- <span class="logo-name">Kuber</span> -->
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <app-feather-icons [icon]="'menu'" [class]="'header-icon'"></app-feather-icons>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- <li class="nav-item">
          <div class="switch-toggle"  [ngClass]="isDarkSidebar? 'dark' : 'light'">
            <span class="light-span" (click)="darkThemeBtnClick()" >
              Light
            </span>
            <span class="dark-span" (click)="lightThemeBtnClick()">
              Dark
            </span>
            <div class="switch" [ngClass]="isDarkSidebar? 'dark' : 'light'">
              
            </div>
          </div>
        </li> -->
        <!-- <li class="nav-item langSelItem" ngbDropdown>
          <a ngbDropdownToggle class="lang-dropdown">
            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">
            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">
          </a>
          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">
            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"
              (click)="setLanguage(item.text, item.lang, item.flag)"
              [ngClass]="{'active': langStoreValue === item.lang}">
              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">{{item.text}}</span>
            </a>
          </div>
        </li> -->
        <!-- #START# Notifications-->
        <!-- <li class="nav-item btnNotification" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <app-feather-icons [icon]="'bell'" [class]="'header-icon'"></app-feather-icons>
            <span class="label-count bg-orange"></span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 350px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="nfc-header">
                  <h5 class="mb-0">Notitications</h5>
                </li>
                <li>
                  <a  *ngFor="let notification of notifications"
                    [ngClass]="[notification.status]">
                    <span class="table-img msg-user ">
                      <i class="material-icons-two-tone nfc-type-icon"
                        [ngClass]="[notification.color]">{{notification.icon}}</i>
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.message}}</span>
                      <span class="menu-desc mt-2">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                    </span>
                    <span class="nfc-close">
                      <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="nfc-read-more">Read
              All Notifications</a>
          </div>
        </li> -->
        <!-- #END# Notifications-->
        <li class="nav-item user_profile btnUserDropdown" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <img src="assets/images/user.png" class="user_img" width="32" height="32" alt="User">
            <span>{{user?.username}}</span>
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a routerLink="/admin/dashboard/profile">
                        <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Minha Conta
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>

      <div (click)="clearCache()"
        style="cursor: pointer; display: flex; align-items: center; margin-left: 50px; margin-top: 20px;">
        <mat-icon style="margin-right: 5px;">cached</mat-icon>
        Limpar Cache
      </div>
    </div>
  </div>
</nav>