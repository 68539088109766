import { Component } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from "@angular/common";
import { AppService } from "./_service/app.service";
import { AppConfig } from "./_service/_interface/app";
import { Title } from "@angular/platform-browser";
import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  currentUrl: string;

  appInfo: AppConfig;

  showPage: boolean;


  constructor(
    public _router: Router,
    public location: PlatformLocation,
    private spinner: NgxSpinnerService,
    private appService: AppService,
    private title: Title,
  ) {  
    this.setPathAPI(window.location.href);
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
  }

  private setPathAPI(path: string) {
    if (path.indexOf("ex.imibanking") !== -1) {
      this.appService.setPath('https://api.imibanking.com');
    }
    if (path.indexOf("sandbox.imibanking") !== -1) {
      this.appService.setPath('https://apisandbox.imibanking.com');
    }
    if (path.indexOf("sandbox.suprem.exchange") !== -1) {
      this.appService.setPath('https://apisandbox.suprem.exchange');
    }
    if (path.indexOf("localhost") !== -1) {
      //teste correção
      this.appService.setPath('https://apisandbox.imibanking.com');
    }
    if (path.indexOf("painel.suprem") !== -1) {
      this.appService.setPath('https://api.suprem.exchange');
    }
    this.getAppInfo();
  }


  getAppInfo(): void {
    this.appService.getAppInfo().subscribe((response) => {
      this.appInfo = response;
      this.title.setTitle(this.appInfo.title);
      localStorage.setItem("app_info", JSON.stringify(this.appInfo))
      this.showPage = true;
    }, (err: HttpErrorResponse) => {
      this.showPage = true;
    });
  }
}
