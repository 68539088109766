import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/config/config.service";
import { LanguageService } from "src/app/core/service/language.service";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { AlertService } from "src/app/_service/alert/alert.service";
import { AppService } from "src/app/_service/app.service";
import { LoadingService } from "src/app/_service/loading/loading.service";
import { AuthService } from "src/app/_service/user/auth.service";
import { AppConfig } from "src/app/_service/_interface/app";
import Swal from "sweetalert2";
const document: any = window.document;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit {

  //---------------------------------------------------------------
  //VARIAVEIS DE TEMPLATE
  public config: any = {};
  userImg: string;
  homePage: string;
  isNavbarCollapsed = true;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;

  appInfo: AppConfig = JSON.parse(localStorage.getItem("app_info"))

  selectedBgColor = "white";
  maxHeight: string;
  maxWidth: string;
  showpanel = false;
  isDarkSidebar = localStorage.getItem("theme") === 'dark' ? true : false;
  isDarTheme = false;
  isRtl = false;

  //---------------------------------------------------------------
  //VARIAVEIS UTEIS
  user: any;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private configService: ConfigService,
    private authService: AuthService,
    private loading: LoadingService,
    private alert: AlertService,
    private router: Router,
    public languageService: LanguageService,
    private appService: AppService
  ) {
    super();
  }
  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
  ];
  notifications: any[] = [
    {
      message: "Please check your mail",
      time: "14 mins ago",
      icon: "mail",
      color: "nfc-green",
      status: "msg-unread",
    },
  ];
  ngOnInit() {
    this.initFunction();
    this.getUser();
  }

  ngAfterViewInit() {
    this.afterViewInitFunction();
  }

  //----------------------------------------------------------------------
  //    FUNCOES DO TEMPLATE
  //----------------------------------------------------------------------


  //----------------------------------------------------------------------
  //FUNCAO DE NGONINIT DO TEMPLATE
  initFunction() {
    this.config = this.configService.configData;
    this.homePage = "admin/dashboard/main";


    this.langStoreValue = localStorage.getItem("lang");
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }//fim initFunction

  //----------------------------------------------------------------------
  //FUNCAO DE NGAFTERVIEWINIT DO TEMPLATE
  afterViewInitFunction() {
    // set theme on startup
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem("menuOption")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menuOption")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "menu_" + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader")
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        "logo-" + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem("sidebar_status")) {
      if (localStorage.getItem("sidebar_status") === "close") {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      } else {
        this.renderer.removeClass(this.document.body, "side-closed");
        this.renderer.removeClass(this.document.body, "submenu-closed");
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, "side-closed");
        this.renderer.addClass(this.document.body, "submenu-closed");
      }
    }
  }//fim afterViewInitFunction


  //----------------------------------------------------------------------
  //FUNCAO DE SETAR IDIOMA
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }//fim setLanguage

  //----------------------------------------------------------------------
  //FUNCAO DE ABRIR MENU SIDEBAR NO MOBILE
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }//fim mobileMenuSidebarOpen

  clearCache() {
    // Limpa o cache do navegador
    // location.reload();
    let url = location.href;
    if (!url.includes('refresh=')) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + 'refresh=' + new Date().getTime();
    } else {
      url = url.replace(/(refresh=)[^\&]+/, '$1' + new Date().getTime());
    }
    location.href = url;
  }

  //----------------------------------------------------------------------
  //FUNCAO DE CHAMAR COLLAPSE DE SIDEMENU
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }//fim callSidemenuCollapse

  //----------------------------------------------------------------------
  //FUNCAO - LIGHT MODE
  lightThemeBtnClick() {
    this.renderer.removeClass(this.document.body, "dark");
    this.renderer.removeClass(this.document.body, "submenu-closed");
    this.renderer.removeClass(this.document.body, "menu_dark");
    this.renderer.removeClass(this.document.body, "logo-black");
    if (localStorage.getItem("choose_skin")) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem("choose_skin")
      );
    } else {
      this.renderer.removeClass(
        this.document.body,
        "theme-" + this.config.layout.theme_color
      );
    }

    this.renderer.addClass(this.document.body, "light");
    this.renderer.addClass(this.document.body, "submenu-closed");
    this.renderer.addClass(this.document.body, "menu_light");
    this.renderer.addClass(this.document.body, "logo-white");
    this.renderer.addClass(this.document.body, "theme-white");
    const theme = "light";
    const menuOption = "menu_light";
    this.selectedBgColor = "white";
    this.isDarkSidebar = false;
    localStorage.setItem("choose_logoheader", "logo-white");
    localStorage.setItem("choose_skin", "theme-white");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menuOption", menuOption);
  }//fim lightThemeBtnClick

  //----------------------------------------------------------------------
  //FUNCAO - DARK MODE
  darkThemeBtnClick() {
    this.renderer.removeClass(this.document.body, "light");
    this.renderer.removeClass(this.document.body, "submenu-closed");
    this.renderer.removeClass(this.document.body, "menu_light");
    this.renderer.removeClass(this.document.body, "logo-white");
    if (localStorage.getItem("choose_skin")) {
      this.renderer.removeClass(
        this.document.body,
        localStorage.getItem("choose_skin")
      );
    } else {
      this.renderer.removeClass(
        this.document.body,
        "theme-" + this.config.layout.theme_color
      );
    }
    this.renderer.addClass(this.document.body, "dark");
    this.renderer.addClass(this.document.body, "submenu-closed");
    this.renderer.addClass(this.document.body, "menu_dark");
    this.renderer.addClass(this.document.body, "logo-black");
    this.renderer.addClass(this.document.body, "theme-black");
    const theme = "dark";
    const menuOption = "menu_dark";
    this.selectedBgColor = "black";
    this.isDarkSidebar = true;
    localStorage.setItem("choose_logoheader", "logo-black");
    localStorage.setItem("choose_skin", "theme-black");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menuOption", menuOption);

  }//fim darkThemeBtnClick


  //----------------------------------------------------------------------

  //-----------------------------------------------------------------------
  //Obter Usuario
  getUser() {
    this.authService.getUser().subscribe(res => {
      if (res.success) {
        localStorage.setItem('user', JSON.stringify(res.data));
        this.user = res.data;
      }
    });
  }//fim getUser

  //----------------------------------------------------------------------
  //LOGOUT
  logout() {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Você deseja sair ?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.value) {
        this.loading.show('Desconectando...');
        setTimeout(() => {
          this.loading.hide();
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("currentUser");
          this.appService.getAppInfo().subscribe((response) => {
            localStorage.setItem("app_info", JSON.stringify(response))
            this.user = null;
            this.router.navigate(['/'])
          });

        }, 2000);
      }
    })
  }//fim logout



}
