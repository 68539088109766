import { Menu } from './../../_service/_interface/menu.interface';


export class SidebarOrg {

    public menu_admin: Menu[] = [{

        name: "Empresa",
        router: "/admin/dashboard/company",
        icon: "settings",
        title: 'companies'

    },

    {
        name: "Configurações",
        router: "/admin/dashboard/configuration",
        icon: "settings",
        title: 'configurations'

    },

    {
        name: "Cotação Manual",
        router: "/admin/dashboard/quote",
        icon: "settings",
        title: 'currency_quote'

    },

    {
        name: "Pagadores",
        router: "/admin/dashboard/configuration-fee",
        icon: "settings",
        title: 'payers'

    },

    {
        name: "Autorizadas",
        router: "/admin/dashboard/subsidiary",
        icon: "home",
        title: 'authorized'

    },

    {
        name: "Usuários",
        router: "/admin/dashboard/user",
        icon: "users",
        title: 'users',
    },

    {
        name: "Remessas",
        router: "/admin/dashboard/invoice",
        icon: "file-text",
        title: 'invoices'
    },

    {
        name: "Financeiro",
        icon: "bar-chart-2",
        title: 'financial',
        itens: [{

            name: "Extrato",
            router: "/admin/dashboard/finance/extract",
            title: 'financial'
        },
        {
            name: "Crédito",
            router: "/admin/dashboard/finance/credit",
            title: 'financial'
        }

        ]
    },

    {
        name: "Gerenciador de Arquivos",
        icon: "file",
        title: 'orchestrator',
        itens: [{

            name: "Inbound",
            router: "/admin/dashboard/orchestrator/inbound",
            title: 'orchestrator',

        },
        {
            name: "Outbound",
            router: "/admin/dashboard/orchestrator/outbound",
            title: 'orchestrator',

        },
        {
            name: "Transmissão",
            router: "/admin/dashboard/orchestrator/transmission",
            title: 'orchestrator',
        }
        ]
    },

    {
        name: "Clientes",
        router: "/admin/dashboard/client",
        icon: "user",
        title: 'clients',
    }

    ]

    public menu_agent: Menu[] = [{

        name: "Dashboard",
        router: "/admin/dashboard/dash-agent",
        icon: "align-left",
        title: 'dashboard'
    },

    {
        name: "Remessas",
        router: "/admin/dashboard/invoice",
        icon: "file-text",
        title: 'invoices'
    },

    {
        name: "Financeiro",
        icon: "bar-chart-2",
        title: 'financial',
        itens: [{
            name: "Extrato",
            router: "/admin/dashboard/finance/extract",
            title: 'financial_extract'

        },
        {
            name: "Crédito",
            router: "/admin/dashboard/finance/credit",
            title: 'financial_credit'
        }

        ]
    },

    {
        name: "Usuários",
        router: "/admin/dashboard/user",
        icon: "users",
        title: 'users'
    },

    {
        name: "Clientes",
        router: "/admin/dashboard/client",
        icon: "user",
        title: 'clients'
    }
    ]

    public menu_manager: Menu[] = [{

        name: "Configiurações",
        router: "/admin/dashboard/configuration",
        icon: "settings"

    },
    {
        name: "Custo Pagador",
        router: "/admin/dashboard/configuration-fee",
        icon: "settings"

    },
    {
        name: "Financeiro",
        icon: "bar-chart-2",
        itens: [{

            name: "Extrato",
            router: "/admin/dashboard/finance/extract"
        },
        {
            name: "Crédito",
            router: "/admin/dashboard/finance/credit"
        }

        ]
    },

    {
        name: "Empresa",
        router: "/admin/dashboard/company",
        icon: "home"
    },

    {
        name: "Autorizadas",
        router: "/admin/dashboard/subsidiary",
        icon: "home"
    },


    {
        name: "Usuários",
        router: "/admin/dashboard/user",
        icon: "users"
    },

    {
        name: "Remessas",
        router: "/admin/dashboard/invoice",
        icon: "file-text"
    },
    ]

    public menu_employee: Menu[] = [{

        name: "Dashboard",
        router: "/admin/dashboard/dash-agent",
        icon: "align-left",
        title: 'dashboard'

    },

    {
        name: "Remessas",
        router: "/admin/dashboard/invoice",
        icon: "file-text",
        title: 'invoices'

    },

    {
        name: "Clientes",
        router: "/admin/dashboard/client",
        icon: "user",
        title: 'clients'
    }


    ]

    public menu_agent_callcenter: Menu[] = [{
        name: "Dashboard",
        router: "/admin/dashboard/dash-agent",
        icon: "align-left",
        title: 'dashboard'
    },

    {
        name: "Remessas",
        router: "/admin/dashboard/invoice",
        icon: "file-text",
        title: 'invoices'
    },

    {
        name: "Financeiro",
        icon: "bar-chart-2",
        title: 'financial',
        itens: [{

            name: "Extrato",
            router: "/admin/dashboard/finance/extract",
            title: 'financial_extract',
        },
        ]
    },


    {
        name: "Clientes",
        router: "/admin/dashboard/client",
        icon: "user",
        title: 'clients',
    }
    ]


}
