import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { apiURL } from '../_helper/url';
import { GetConversionData } from './_interface/invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  apiURL: any = localStorage.getItem("urlData"); //URL DA API
  apiURLTemp: any = apiURL.urlTemp; //URL DA API

  constructor(private http: HttpClient) { }

  //---------------------------------------------------------------
  //Obter Remessas
  getInvoices(limit: number, page: number, date_start?: Date, date_end?: Date, reference?: null, id_user: number = undefined, status?: any, id_payer?: any, id_collaborator?: any, reference_date?: null, deny_status?: null) {
    return this.http.get<any>(`${this.apiURL}/invoice/list`, {
      params: {
        limit: limit,
        page: page,
        ...date_end == undefined ? undefined : {
          date_end: date_end.toISOString().split('T')[0],
        },
        ...date_start == undefined ? undefined : {
          date_start: date_start.toISOString().split('T')[0],
        },
        ...reference == null ? undefined : {
          reference: reference
        },
        ...status == null ? undefined : {
          status: status
        },
        ...id_payer == null ? undefined : {
          id_payer: id_payer
        },
        ...id_collaborator == null ? undefined : {
          id_collaborator: id_collaborator
        },
        ...id_user == null ? undefined : {
          id_user: id_user
        },
        ...reference_date == null ? undefined : {
          reference_date: reference_date
        },
        ...deny_status == null ? undefined : {
          deny_status: deny_status
        }
      }
    })
  }

  //---------------------------------------------------------------
  //Obter Unica Remessa
  getUniqueInvoice(id: number) {
    return this.http.get<any>(`${this.apiURL}/invoice/` + id)
  }

  //---------------------------------------------------------------
  //Criar Remessa
  create(form: FormGroup) {
    let formData: FormData = new FormData();
    // formData.append("id_sender", form.value.id_sender);
    formData.append("id_sender", form.value.id_sender);
    formData.append("id_calculator", form.value.id_calculator);
    formData.append("id_beneficiary", form.value.id_beneficiary);
    formData.append("id_operation", form.value.id_operation);
    formData.append("id_purpose", form.value.id_purpose);
    formData.append("id_relationship", form.value.id_relationship);
    formData.append("id_origin_funds", form.value.id_origin_funds);
    formData.append("discount", form.value.discount);
    typeof form.value.amount !== "undefined" ? formData.append("amount", form.value.amount) : false;
    formData.append("currency_from", form.value.coin_amount_from);
    formData.append("currency_to", form.value.coin_amount_to);
    formData.append("id_country", form.value.id_country);
    formData.append("observation", form.value.comments);
    formData.append("id_delivery_method", form.value.method);
    formData.append("id_company", form.value.payer);
    formData.append("id_bank_account", form.value.id_bank_account);
    formData.append("id_subsidiary", form.value.id_subsidiary);
    typeof form.value.total_amount !== "undefined" ? formData.append("total_amount", form.value.total_amount) : false;

    // form?.value?.id_payment_point?.id ? formData.append("id_payment_point", form?.value?.id_payment_point?.id) : false;

    form?.value?.id_payment_point
      ? formData.append('id_payment_point', form?.value?.id_payment_point)
      : false;

    return this.http.post<any>(`${this.apiURL}/invoice`, formData)
  }

  //---------------------------------------------------------------
  //Criar Remessa - Arquivo
  createFile(form: FormGroup) {
    const formData = new FormData();
    form.value.file.forEach(file => formData.append('file', file, file.name));
    return this.http.post<any>(`${this.apiURL}/invoice/file`, formData)
  }

  //---------------------------------------------------------------
  //Obter lista de moedas
  getListCoin(): Observable<any> {
    return this.http.post<any>(`${this.apiURL}`, {});
  }

  //---------------------------------------------------------------
  //Obter conversão
  getConversion(params: any): Observable<GetConversionData> {
    !params?.total_amount ? params.total_amount = 0 : false;
    !params?.amount ? params.amount = 0 : false;
    return this.http.get<GetConversionData>(`${this.apiURL}/exchange/calculator`, {
      params: {
        ...params
      }
    });
  }

  //---------------------------------------------------------------
  //Obter limite de desconto
  getDiscount() {
    return this.http.get<any>(`${this.apiURL}/configuration/discount`);
  }

  //---------------------------------------------------------------
  // Obter lista de origem dos fundos
  getPurposeList(id?: any) {
    return this.http.get<any>(`${this.apiURL}/invoice/purpose/${id}`);
  }

  //---------------------------------------------------------------
  // Obter relação do remetente e do destinatário
  getRelation(id?: any) {
    return this.http.get<any>(`${this.apiURL}/invoice/relationship/${id}`);
  }

  //---------------------------------------------------------------
  // Obter Contas Bancarias
  getBankAccountList(idUser: number) {
    return this.http.get<any>(`${this.apiURL}/client/bankAccount/${idUser}`);
  }

  getBankVerifyAccount(idUser: number) {
    return this.http.get<any>(`${this.apiURL}/client/verify/${idUser}`);
  }

  sendDocumentInvoice(obj: any) {

    let formData: FormData = new FormData();
    formData.append("id_invoice", obj.id_invoice);
    formData.append("number", obj.number);
    formData.append("type", obj.type);
    // formData.append("files ", obj.value.files);
    obj.files.forEach(files => formData.append('files', files, files.name));

    return this.http.post<any>(`${this.apiURL}/invoice/sendDocument`, formData)
  }

  //---------------------------------------------------------------
  // Obter Dados de Dashboard Invoice
  getDashboard() {
    return this.http.get<any>(`${this.apiURL}/invoice/summary`);
  }

  //---------------------------------------------------------------
  //Obter Origem dos Fundos
  getOriginOfFounds(id?: any) {
    return this.http.get<any>(`${this.apiURL}/invoice/originOfFunds/${id}`)
  }

  getOriginOfMarkets(id?: any) {
    return this.http.get<any>(`${this.apiURL}/invoice/originOfFunds/${id}`)
  }

  // Get all status for cancel invoice
  getAllStatus() {
    return this.http.get<any>(`${this.apiURL}/invoice/statusList`)
  }

  // Cancel invoice
  cancelInvoice(form: any) {
    return this.http.post<any>(`${this.apiURL}/invoice/changeStatus/${form.id}`, {
      status: form.status,
      observation: form.observation
    });
  }

  approveInvoice(id: any, observation: any) {
    return this.http.post<any>(`${this.apiURL}/invoice/approve/payment/${id}`, { observation: observation });
  }

  addMessageInvoice(id: any, message: any) {
    return this.http.post<any>(`${this.apiURL}/invoice/message`, { id_invoice: id, message: message });
  }

  removeInvoice(id: any, observation: any) {
    return this.http.request<any>('delete', `${this.apiURL}/invoice/${id}`, { body: { observation: observation } });
  }


  sendInvoiceNew(id: any) {
    return this.http.post<any>(`${this.apiURL}/invoice/release/transmission`, {
      ids: id
    })
  }

  sendInvoice(id: any) {
    return this.http.post<any>(`${this.apiURL}/invoice/transmit`, {
      ids: id
    })
  }

  sendNewReceipt(id: any) {
    return this.http.post<any>(`${this.apiURL}/invoice/receipt`, {
      ids: [id]
    })
  }
  //---------------------------------------------------------------
}


