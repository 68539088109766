<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">

          <!-- <span class="version-app">version: {{appInfo?.version}}</span> -->
          <span class="version-app">version: 2.1.1</span>
          <div class="user-panel">
            <div class=" image">
              <img src="assets/images/user.png" class="img-circle user-img-circle" alt="User Image" />
            </div>
          </div>
          <!-- 
            appInfo.logo.horizontal
           -->
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name">{{user?.username}} </div>
            <div *ngIf="user?.role == 'MANAGER'" class="profile-usertitle-job ">MANAGER</div>
            <div *ngIf="user?.role == 'ADMIN'" class="profile-usertitle-job ">ADMINISTRADOR</div>
            <div *ngIf="user?.role == 'AGENT'" class="profile-usertitle-job ">AGENTE</div>
            <div *ngIf="user?.role == 'PAYER'" class="profile-usertitle-job ">PAGADOR</div>
          </div>
        </li>

        <div *ngFor="let item of items_menu">

          <li>
            <a [routerLink]="item.router" routerLinkActive="submenu-active"
              (click)="item?.itens ? callToggleMenu($event, 1):false" href="javascript:void(0)" class="menu-top">
              <i-feather [name]="item.icon" class="sidebarIcon"></i-feather>
              <span class="hide-menu">{{item.name}}
              </span>
            </a>
            <ul class="ml-menu" *ngIf=" item.itens">
              <li *ngFor=" let sub_item of item.itens ">
                <a [routerLink]="sub_item.router" routerLinkActive="submenu-active">
                  {{sub_item.name}}
                </a>
              </li>

            </ul>
          </li>
          
        </div>

        <li>
          <a class="" (click)="logout()" class="menu-top">
            <i-feather name="log-out" class="sidebarIcon"></i-feather>
            <span class="hide-menu">Sair
            </span>
          </a>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>